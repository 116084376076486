import React, { useEffect, useState } from "react";
import logo from "../../assets/images/Black-Wordmark-VML.svg";
import logout from "../../assets/images/log-out.svg";
import { ReactComponent as MenuBars } from "../../assets/images/menu-bars.svg";
import { ReactComponent as XBar } from "../../assets/images/x-icon.svg";
import { useStyles } from './Header.styles';
import { useOktaAuth } from '@okta/okta-react';


interface IProps {
  handleClick: () => void;
  isActive: boolean;
}

const HeaderComponent = (props: IProps) => {
  const { handleClick, isActive } = props;
  const Bars = isActive ? MenuBars : XBar;
  const classes = useStyles();
  const { authState, oktaAuth } = useOktaAuth();
  const [userName, setUsername] = useState("");


  useEffect(() => {
    if (authState?.isAuthenticated) {
      oktaAuth.getUser().then(
        (result) => {
          if (result.given_name && result.family_name) {
            setUsername(result.given_name + " " + result.family_name);
          }
        },
        (error) => { console.log(error) }
      )
    }
  }, [authState, oktaAuth])

  const logOutRedirect = async () => {
    await oktaAuth.signOut();
  };

  return (
    <header className={classes.header}>
      <button className={classes.side_menu_bar} onClick={handleClick}>
        <Bars className={classes.head_icon}></Bars>
      </button>
      <img src={logo} className={classes.logo_css} alt={"VML logo"} />
      <h1 className={classes.head_message}>
        {"Bienvenido,"} {userName}
      </h1>
      <button className={classes.head_button} onClick={logOutRedirect}>
        <img src={logout} className={`${classes.log_out_icon} log_out_button`} alt="logout icon" />
      </button>
    </header>
  );
};

export default HeaderComponent;

