import { OktaAuth } from '@okta/okta-auth-js';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CALLBACK_PATH = process.env.REACT_APP_CALLBACK_PATH;
const ISSUER = 'https://wpp.okta.com';
const SCOPES = process.env.REACT_APP_SCOPES;
const HOST = process.env.REACT_APP_HOST_URL;
const REDIRECT_URI = `${HOST}${CALLBACK_PATH}`;

if (!SCOPES || !CLIENT_ID || !CALLBACK_PATH || !ISSUER || !HOST) {
  throw new Error('All environmental variables must be set');
}

const config = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES.split(/\s+/),
};

const oktaAuth = new OktaAuth(config);
export default oktaAuth;
