import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import oktaAuth from './okta.provider';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_ENDPOINT_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = oktaAuth.getIdToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Table: {
        keyFields: ['id', 'floorId'],
      },
      Workstation: {
        keyFields: ['id', 'tableId', 'floorId'],
      },
      Reservation: {
        fields: {
          workstationreservation: {
            merge: false,
          },
        },
      },
      Query: {
        fields: {
          getAllReservations: {
            merge: false,
          },
          getReservationByDateAndFloor: {
            merge: false,
          },
        },
      },
    },
  }),
});

export default apolloClient;
