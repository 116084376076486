import React, { useCallback, useEffect, useState } from 'react';
import { useStyles } from './Page.styles';
import dayjs from 'dayjs';
import OccupationInformationComponent from '../components/OccupationInformation/OccupationInformation';
import CircularProgressCardComponent from '../components/CircularProgressCard/CircularProgressCard';
import { gql, useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { IMapPageData } from '../interfaces/IMapPage';
import { IFloor } from '../interfaces/IFloor';
import { IReservationData } from '../interfaces/IReservationData';
import { INotification } from '../interfaces/IAdmin';
import CustomDatePicker from '../components/Calendar/Calendar';
import DeskAvailability from '../components/DeskAvailability/DeskAvailability';
import StationSelection from '../components/StationSelection/StationSelection';
import { ITable } from '../interfaces/ITable';
import { WorkStationStatus } from '../interfaces/IWorkstation';
import { IReservation } from '../interfaces/IReservation';
import SetOccupationComponent from '../components/SetOccupation/SetOccupation';

interface IProps {
  handleDate: (date: Date) => void;
  currentDate: Date;
}

const AdminMainPage = (props: IProps) => {
  const classes = useStyles();
  const [date, setDate] = useState(props.currentDate);
  const [floorId, setFloorId] = useState(2);
  const [selectedFloor, setSelectedFloor] = useState<IFloor>();
  const [workstationsCapacity, setWorkstationsCapacity] = useState(0);
  const [totalWorkstations, setTotalWorkstations] = useState(0);
  const [notificationsData, setNotificationsData] = useState<INotification[]>(
    []
  );
  const [userEmail] = useState('');
  const [userActualReservation, setUserActualReservation] =
    useState<IReservation>();
  const [stations, setStations] = useState<ITable[]>([]);
  const [percentageAllowed, setPercentageAllowed] = useState(0);
  const [originalPercentageAllowed, setOriginalPercentageAllowed] = useState(0);

  const floorsQuery = gql`
    query {
      floors: getAllFloors {
        id
        floorCapacity
        acceptedCapacity
        floorName
        tables {
          id
          floorId
          workstations {
            id
            status
            floorId
            tableId
          }
        }
      }
    }
  `;

  const reservationsQuery = gql`
    query ($dateParam: String!, $pFloorId: Float!, $pAreaId: Float!) {
      reservations: getReservationByDateAndFloorAndArea(
        dateParam: $dateParam
        pFloorId: $pFloorId
        pAreaId: $pAreaId
      ) {
        id
        userEmail
        date
        workstationreservation {
          workstationId
          tableId
        }
      }
    }
  `;

  const allReservationsQuery = gql`
    query {
      reservations: getAllReservations {
        id
        userEmail
        date
        status
        workstationreservation {
          reservationId
          workstationId
          tableId
          floorId
        }
      }
    }
  `;

  const updateFloorQuery = gql`
    mutation (
      $floorName: String!
      $floorCapacity: Float!
      $acceptedCapacity: Float!
      $id: Int!
    ) {
      result: updateFloor(
        floor: {
          id: $id
          floorCapacity: $floorCapacity
          acceptedCapacity: $acceptedCapacity
          floorName: $floorName
        }
      ) {
        id
      }
    }
  `;

  const changeStationsStatus = () => {
    setUserActualReservation(undefined);
    if (selectedFloor !== undefined) {
      const updatedTables = selectedFloor.tables.map((table) => {
        return {
          id: table.id,
          floorId: table.floorId,
          workstations: table.workstations.map((station) => {
            const userReservation = searchUserReservation(
              station.id,
              station.tableId
            );
            return {
              ...station,
              reservationStatus:
                station.status === false
                  ? WorkStationStatus.disabled
                  : userReservation === 'actual'
                  ? WorkStationStatus.actual
                  : userReservation === 'occupied'
                  ? WorkStationStatus.occupied
                  : WorkStationStatus.available,
            };
          }),
        };
      });
      setStations(updatedTables);
    }
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const floorsData = useQuery<IMapPageData>(floorsQuery, {
    pollInterval: 3000,
  });

  const [getReservationsData, reservationsData] =
    useLazyQuery<IReservationData>(reservationsQuery, {
      variables: {
        dateParam:
          date.getFullYear().toString() +
          '/' +
          (date.getMonth() + 1).toString() +
          '/' +
          date.getDate().toString(),
        pFloorId: selectedFloor?.id || 2,
        pAreaId: 1,
      },
      pollInterval: 1000,
    });

  const allReservationsData = useQuery<IReservationData>(allReservationsQuery, {
    pollInterval: 3000,
  });

  const searchUserReservation = useCallback(
    (id: string, tableId: string) => {
      if (reservationsData.data !== undefined) {
        let result = '';
        let reservation = reservationsData.data?.reservations.find(
          (element) => {
            if (
              element.workstationreservation.workstationId === id &&
              element.workstationreservation.tableId === tableId
            ) {
              result = 'actual';
              return element;
            } else if (
              element.workstationreservation.workstationId === id &&
              element.workstationreservation.tableId === tableId
            ) {
              result = 'occupied';
              return element;
            }
            return null;
          }
        );
        if (result === 'actual') {
          setUserActualReservation(reservation);
        }
        return result;
      }
    },
    [reservationsData.data]
  );

  const [updateFloor] = useMutation(updateFloorQuery, {
    variables: {
      id: selectedFloor?.id,
      floorCapacity: selectedFloor?.floorCapacity,
      acceptedCapacity: percentageAllowed,
      floorName: selectedFloor?.floorName,
    },
    refetchQueries: [
      {
        query: floorsQuery,
      },
    ],
  });

  useEffect(() => {
    if (selectedFloor) {
      getReservationsData();
      setPercentageAllowed(selectedFloor.acceptedCapacity);
      setOriginalPercentageAllowed(selectedFloor.acceptedCapacity);
      const totalWorkstations = selectedFloor.tables.reduce((total, table) => {
        return total + table.workstations.length;
      }, 0);
      setTotalWorkstations(totalWorkstations);
      setWorkstationsCapacity(
        selectedFloor?.acceptedCapacity
      );
    }
  }, [selectedFloor, getReservationsData]);

  useEffect(changeStationsStatus, [
    selectedFloor,
    reservationsData.data,
    userEmail,
    searchUserReservation,
  ]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedFloor !== undefined) {
      getReservationsData();
      let res = 0;
      selectedFloor?.tables.forEach((element) => {
        res = res + element.workstations.length;
      });
    }
  }, [date, selectedFloor, getReservationsData]);

  useEffect(() => {
    setDate(props.currentDate);
  }, [props.currentDate]);
  useEffect(() => {
    floorsData.data &&
      floorsData.data.floors &&
      setSelectedFloor(
        floorsData.data.floors.find((floor) => floor.id === floorId)
      );
    if (floorsData.previousData && floorsData.data) {
      floorsData.data.floors.forEach((e) => {
        const floor = floorsData.previousData?.floors.find(
          (newFloor) => newFloor.id === e.id
        );
        if (floor) {
          if (e.acceptedCapacity !== floor.acceptedCapacity) {
            const newCapacity = notificationsData.find(
              (notification) =>
                notification.id ===
                'acceptedCapacity' + e.id + e.acceptedCapacity
            );
            if (newCapacity === undefined) {
              const newNotification: INotification = {
                id: 'acceptedCapacity' + e.id + e.acceptedCapacity,
                date: dayjs(new Date()).format('DD/MM/YYYY'),
                message:
                  'La máxima capacidad del ' +
                  e.floorName.toLowerCase() +
                  ' ha cambiado al ' +
                  e.acceptedCapacity +
                  '%',
              };
              setNotificationsData([newNotification].concat(notificationsData));
            }
          }
        }
      });
    }
  }, [floorsData.previousData, floorsData.data, floorId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getReservationsData();
    if (selectedFloor) {
      const totalWorkstations = selectedFloor.tables.reduce((total, table) => {
        return total + table.workstations.length;
      }, 0);
      setTotalWorkstations(totalWorkstations);
      setWorkstationsCapacity(
        selectedFloor?.acceptedCapacity
      );
    }
  }, [selectedFloor, getReservationsData]);

  // const deleteNotification = (id: string) => {
  //   setNotificationsData(
  //     notificationsData.filter((e) => {
  //       return e.id !== id;
  //     })
  //   );
  // };

  useEffect(() => {
    if (reservationsData.data && workstationsCapacity > 0) {
      if (reservationsData.data.reservations.length === workstationsCapacity) {
        const maxOccupationNotification = notificationsData.find(
          (e) => e.id === 'maxOccupation'
        );
        if (maxOccupationNotification === undefined) {
          const newNotification: INotification = {
            id: 'maxOccupation',
            date: dayjs(new Date()).format('DD/MM/YYYY'),
            message: 'Máxima ocupación del día alcanzada',
          };
          setNotificationsData([newNotification].concat(notificationsData));
        }
      } else if (
        reservationsData.data.reservations.length ===
        Math.trunc((70 * workstationsCapacity) / 100)
      ) {
        const nearMaxOccupationNotification = notificationsData.find(
          (e) => e.id === 'nearMaxOccupation'
        );
        if (nearMaxOccupationNotification === undefined) {
          const newNotification: INotification = {
            id: 'nearMaxOccupation',
            date: dayjs(new Date()).format('DD/MM/YYYY'),
            message: 'Acercándose a la máxima ocupación del día',
          };
          setNotificationsData([newNotification].concat(notificationsData));
        }
      }
    }
  }, [reservationsData.data, workstationsCapacity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (allReservationsData.previousData && allReservationsData.data) {
      let previousDataLength =
        allReservationsData.previousData.reservations.length;
      const newNotifications: INotification[] = [];
      while (
        previousDataLength < allReservationsData.data.reservations.length
      ) {
        const stationId =
          allReservationsData.data.reservations[previousDataLength]
            .workstationreservation.floorId +
          allReservationsData.data.reservations[previousDataLength]
            .workstationreservation.tableId +
          allReservationsData.data.reservations[previousDataLength]
            .workstationreservation.workstationId;
        const reservationDate =
          allReservationsData.data.reservations[previousDataLength].date;
        let id = allReservationsData.data.reservations[previousDataLength].id;
        newNotifications.push({
          id: id.toString(),
          date: dayjs(new Date()).format('DD/MM/YYYY'),
          message:
            'Hay una nueva reservación en la estación ' +
            stationId +
            ' el ' +
            dayjs(reservationDate).format('DD/MM/YYYY'),
        });
        previousDataLength = previousDataLength + 1;
      }
      setNotificationsData(newNotifications.concat(notificationsData));
    }
  }, [allReservationsData.previousData, allReservationsData.data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="full">
        <div className={classes.mainTitleDivSlim}>
          <h1 className={classes.titleFont}>Inicio</h1>
        </div>
        {/* <h2 className={`${classes.titleFont} date`}>{date.format("dddd, DD ")} de {date.format(" MMMM, YYYY")}</h2> */}
        <div className={classes.floorDateDiv}>
          <div className={classes.dateDiv}>
            <h2 className={classes.dateFont}>Fecha</h2>
            <div className={classes.dateInput}>
              <CustomDatePicker
                date={props.currentDate}
                today={today}
                handleClick={props.handleDate}
                small={true}
                isAdmin
              />
            </div>
          </div>

          <div className={classes.floorSelection}>
            <div
              className={`${classes.floorOption} ${
                floorId === 2 ? 'active' : ''
              }`}
              onClick={() => {
                setFloorId(2);
              }}
            >
              Segundo piso
            </div>
            <div
              className={`${classes.floorOption} right ${
                floorId === 3 ? 'active' : ''
              }`}
              onClick={() => {
                setFloorId(3);
              }}
            >
              Tercer piso
            </div>
          </div>
        </div>

        <OccupationInformationComponent
          maxCapacity={workstationsCapacity}
          percentageOcuppiedExpected={totalWorkstations}
          percentageOcuppied={
            reservationsData.data !== undefined
              ? reservationsData.data.reservations.length
              : 0
          }
          occupation={
            reservationsData.data === undefined ||
            reservationsData.data.reservations.length === 0
              ? 'light'
              : reservationsData.data.reservations.length ===
                workstationsCapacity
              ? 'max'
              : reservationsData.data.reservations.length >=
                Math.trunc((70 * workstationsCapacity) / 100)
              ? 'medium'
              : 'light'
          }
        />
        <div className={classes.infoDiv}>
          <CircularProgressCardComponent
            title={'Estaciones de trabajo ocupadas'}
            type={1}
            maxCapacity={workstationsCapacity}
            percentageOcuppied={
              reservationsData.data !== undefined
                ? reservationsData.data.reservations.length
                : 0
            }
          />

          <div className={classes.mapDivContainer}>
            <DeskAvailability isAdmin />
            <StationSelection
              date={props.currentDate}
              updateDialogBoxSucces={() => {}}
              actualReservation={userActualReservation}
              updateActualReservation={setUserActualReservation}
              stations={stations}
              filter={false}
              updateSelectedStation={() => {}}
              floorId={selectedFloor ? selectedFloor.id : floorId}
              refetchReservations={getReservationsData}
              setShowErrorDailog={() => {}}
              setDialogMessage={() => {}}
              reservationsUpdated={false}
              setReservationsUpdated={() => {}}
              isFullCapacity={false}
              userEmail={''}
              userName={''}
              isAdmin={true}
            ></StationSelection>
          </div>
          {/* <AdminNotificationsComponent notifications={notificationsData} deleteNotification={deleteNotification}/> */}
        </div>
        <SetOccupationComponent
          percentageAllowed={percentageAllowed}
          changePercentageAllowed={setPercentageAllowed}
          originalPercentageAllowed={originalPercentageAllowed}
          updateFloor={updateFloor}
        />
      </div>
    </>
  );
};

export default AdminMainPage;
